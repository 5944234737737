import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/Clients.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/Hero.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/Projects.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BentoGrid","BentoGridItem"] */ "/vercel/path0/components/ui/BentoGrid.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FloatingNav"] */ "/vercel/path0/components/ui/FloatingNav.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Button"] */ "/vercel/path0/components/ui/MovingBorders.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
